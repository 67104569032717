import * as _stream2 from "stream";

var _stream = "default" in _stream2 ? _stream2.default : _stream2;

import * as _mimicResponse2 from "mimic-response";

var _mimicResponse = "default" in _mimicResponse2 ? _mimicResponse2.default : _mimicResponse2;

var exports = {};
const PassThrough = _stream.PassThrough;
const mimicResponse = _mimicResponse;

const cloneResponse = response => {
  if (!(response && response.pipe)) {
    throw new TypeError("Parameter `response` must be a response stream.");
  }

  const clone = new PassThrough();
  mimicResponse(response, clone);
  return response.pipe(clone);
};

exports = cloneResponse;
export default exports;